import { Controller } from "stimulus";

export default class extends Controller {
  checkDocNum(event) {
    event.preventDefault();

    const formData = new FormData(event.target);
    const docNum = formData.get('donor_request[doc_num]');
    const objectId = formData.get('donor_request[id]');

    const url = `/donor/processing_requests/check_doc_num?doc_num=${docNum}&id=${objectId}`;

    fetch(url, {
      method: 'GET',
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.exists) {
          const confirmContinue = confirm('Такой номер удостоверения уже существует в базе данных. Вы хотите продолжить?');

          if (confirmContinue) {
            event.target.submit();
          } else {
            this.dispatchUnlockEvent(event.target);
            return;
          }
        } else {
          event.target.submit();
        }
      })
      .catch(error => {
        console.error('Ошибка при проверке номера удостоверения:', error);
        this.dispatchUnlockEvent(event.target);
      });
  }

  dispatchUnlockEvent(formElement) {
    const unlockEvent = new CustomEvent('custom:unlock', { bubbles: true });
    formElement.dispatchEvent(unlockEvent);
  }
}

